import { useState, useEffect } from 'react';

const ImageGallery = () => {
  const images = [
    {
      src: "https://img.rareprob.com/img/website/rp_webimg/IMG_20220828_134332.webp",
      alt: "Modern white building with blue sky"
    },
    {
      src: "https://img.rareprob.com/img/website/rp_webimg/Background16.webp",
      alt: "Pendant light fixture"
    },
    {
      src: "https://img.rareprob.com/img/website/rp_webimg/9d1683a35966030efec3d5f46f8ae179.webp",
      alt: "Minimal wall sign"
    },
    {
      src: "https://img.rareprob.com/img/website/rp_webimg/59e368348bd77172fcab043136009b35.webp",
      alt: "White geometric sculpture"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-8">
      <h2 className="text-3xl md:text-[51.188px]  font-bold text-center mb-8">Events at Rareprob</h2>
      
      <div className="relative">
        {/* Main Image Display */}
        <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg shadow-xl">
          <img
            src={images[currentIndex].src}
            alt={images[currentIndex].alt}
            className="w-full h-full object-cover"
          />
        </div>

        {/* Thumbnail Navigation */}
        <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className="relative aspect-w-16 aspect-h-9 overflow-hidden rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <img
                src={image.src}
                alt={image.alt}
                className={`w-full h-full object-cover transition-all duration-300 ${
                  currentIndex === index
                    ? ' opacity-60 ring-2 ring-blue-500'
                    : 'opacity-100'
                }`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;



