import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaPinterest, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer: React.FC = () => {
  return (
    <div className="w-full min-h-[400px] p-4 relative bg-black">
      <div className="glass w-full h-full">
        <div className="flex flex-col md:flex-row md:justify-between mx-4 md:mx-10 gap-8 md:gap-4 pb-36">
          <div className="p-5">
            <img
              src='/images/homepage/rare-logo.webp'
              alt="Logo"
              className="py-[5px] w-[130px] md:w-[160px] lg:w-[200px] h-auto"
            />
          </div>
          
          <div className="p-5">
            <p className="text-base text-white font-bold tracking-wide">CONTACT</p>
            <div className="mt-4 md:mt-6 space-y-2">
              <p className="text-base md:text-lg text-white font-semibold break-words">
                <a href="mailto:info@rareprob.com" className="hover:text-gray-300 transition-colors duration-300">info@rareprob.com</a>
              </p>
           
              <p className="text-base md:text-lg text-white font-normal">
                <a href="https://www.linkedin.com/in/harshit-joshi-120910279/" className="hover:text-gray-300 transition-colors duration-300">
                Suite 1305, Building 1000,
                <br></br> Doon Business Express Park,
                <br></br> Subhash Nagar, Dehradun
                </a>
              </p>
            </div>
          </div>

          <div className="p-5">
            <p className="text-base text-white font-bold tracking-wide">USEFUL LINKS</p>
            <div className="mt-4 md:mt-6 space-y-2">
              <div><Link to="/apps" className="text-base md:text-lg text-[#d5d5d5] font-normal hover:text-white transition-colors duration-300">Apps</Link></div>
              <div><Link to="/games" className="text-base md:text-lg text-[#d5d5d5] font-normal hover:text-white transition-colors duration-300">Games</Link></div>
              <div><Link to="/blogs" className="text-base md:text-lg text-[#d5d5d5] font-normal hover:text-white transition-colors duration-300">Blogs</Link></div>
              <div><Link to="/career" className="text-base md:text-lg text-[#d5d5d5] font-normal hover:text-white transition-colors duration-300">Career</Link></div>
            </div>
          </div>

          <div className="p-5">
            <p className="text-base text-white font-bold tracking-wide">SOCIALS</p>
            <div className="mt-4 md:mt-6 flex gap-3">
              <Link to="https://www.instagram.com/rareprob_/" className="hover:opacity-75 transition-opacity duration-300">
                <FaInstagram size={15} className="text-white"/>
              </Link>

              <Link to="https://www.facebook.com/rareprob" className="hover:opacity-75 transition-opacity duration-300">
                <FaFacebookF size={15} className="text-white"/>
              </Link>

              <Link to="https://www.linkedin.com/company/rareprob/" className="hover:opacity-75 transition-opacity duration-300">
                <FaLinkedinIn size={15} className="text-white"/>
              </Link>

              <Link to="https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ" className="hover:opacity-75 transition-opacity duration-300">
                <FaYoutube size={15} className="text-white"/>
              </Link>

              <Link to="https://in.pinterest.com/rare_prob/" className="hover:opacity-75 transition-opacity duration-300">
                <FaPinterest size={15} className="text-white"/>
              </Link>

              <Link to="https://twitter.com/rareprob" className="hover:opacity-75 transition-opacity duration-300">
                <FaXTwitter size={15} className="text-white"/>
              </Link>
            </div>
          </div>
        </div>

        <div className="p-5 absolute bottom-3 right-0 w-full">
          <p className="text-[#d4d4d4] text-center text-sm md:text-md font-light border-t-[1px] border-white/20 pt-3">
            Copyright © 2025 Rareprob. All rights reserved. <Link to="/privacy-policy" className="hover:text-white transition-colors duration-300">/Privacy</Link>
          </p>
        </div>
      </div>
      {/* <img
        src="/blur1.png"
        className="absolute bottom-1 right-6 w-[200px] md:w-[300px] lg:w-[400px] h-auto"
        alt="Blur effect"
      /> */}
    </div>
  );
};

export default Footer;