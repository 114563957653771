import React, { useState } from 'react';
import { FaMinus, FaPlus } from "react-icons/fa";

interface FAQItem {
  question: string;
  answer: string;
}

interface StatCardProps {
  number: string;
  text?: string;
  color: string;
  className: string;
}

const StatCard: React.FC<StatCardProps> = ({ number, text, color, className }) => (
  <div className={`absolute ${className}`}>
    <div 
      className={`w-48 h-48 rounded-3xl p-6 transform rotate-3 transition-transform hover:rotate-0 hover:scale-105 ${color}`}
      // style={{
      //   boxShadow: `0 0 40px ${color === 'bg-cyan-400' ? '#22d3ee' : '#fef08a'}`,
      // }}
    >
      <div className="text-gray-800">
        <p className="text-sm mb-2">Rareprob</p>
        <p className="text-6xl font-bold mb-4">{number}%</p>
        <p className="text-sm leading-tight">
        {text}
        </p>
      </div>
    </div>
  </div>
);

const DarkFAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number>(2);

  const faqs: FAQItem[] = [
    {
      question: "What types of apps do you develop ?",
      answer: "We develop utility apps that make your daily tasks easier. We have so far developed, HD Video Player apps, a music player, a file manager, a gallery app with photo collage-making features, an HD Video Downloader, and a video-to-MP3 converter."
    },
    {
      question: "Do your apps work on Android & iOS ?",
      answer: "Our Video Player and Music Player are available both on Android and iOS.Our Video Downloader, Gallery, Video to MP3 Converter, and File Manager are currently limited to Android. We are planning to expand them as well."
    },
    {
      question: "How can I contact support ?",
      answer: "You can reach us through our support email info@rareprob.com. Our support team will connect with you as soon as possible."
    },
    {
      question: "Are your apps free to use ?",
      answer: "Most of our apps offer free versions with optional premium features that enhance the experience."
    },
    {
      question: "Do your apps contain ads ?",
      answer: "Some of our apps are ad-supported, but we also offer ad-free premium versions."
    }
  ];

  const handleAccordionClick = (index: number): void => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="min-h-screen  py-16 px-4 relative overflow-hidden">
    <h1 className="text-3xl md:text-[51.188px] font-bold text-center mb-16 leading-tight:[-0.56px]">Have any questions?</h1>
      <StatCard 
        number="62" 
        color="bg-cyan-400" 
        className="left-4 xl:left-[15%] top-[40%] -z-10 hidden lg:block"
        text='improving for better experience.'
      />
      <StatCard 
        number="38" 
        color="bg-yellow-200" 
        className="right-4 xl:right-[13%] top-[40%] -z-10  hidden lg:block" 
        text='is embracing new challenges to expand its horizons.'
      />
      
      <div className="max-w-3xl mx-auto relative">
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="glass_brightness rounded-lg overflow-hidden transition-all duration-500 "
            >
              <button
                className="w-full px-6 py-4 flex justify-between items-center text-left"
                onClick={() => handleAccordionClick(index)}
                type="button"
                aria-expanded={openIndex === index}
              >
                <span className="text-sm md:text-lg font-medium text-black">
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <FaMinus className="h-3 w-3 mr-2 text-black flex-shrink-0" />
                ) : (
                  <FaPlus className="h-3 w-3 mr-2 text-black flex-shrink-0" />
                )}
              </button>
              
              <div
                className={`px-6 transition-all duration-200 ease-in-out overflow-hidden ${
                  openIndex === index ? 'max-h-96 pb-4' : 'max-h-0'
                }`}
              >
                <p className="text-black ">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DarkFAQ;