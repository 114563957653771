import React from 'react'
import { Link } from 'react-router-dom';

type MegaMenuProp = {
    setAppMegaMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const apps = [
  { 
    id: 1, 
    name: 'Rocks Player',
    description: 'Enjoy your favorite tracks with enhanced features',
    icon: "/images/Renew/Icons/Video player.png",
    link: "/apps/rocks-video-player-app",
  },
  { 
    id: 2, 
    name: 'File Manager',
    description: 'Organize and manage your files effortlessly',
    icon: "/images/Renew/Icons/File manager.png",
    link:"/apps/file-manager-app"
  },
  { 
    id: 3, 
    name: 'Video Downloader',
    description: 'Edit and create stunning photo collages',
    icon: "/images/Renew/Icons/videoDownloader.webp",
    link:"/apps/video-downloader-files-hider-app"
  },
  { 
    id: 4, 
    name: 'Video To Mp3 Converter',
    description: 'Download videos from multiple platforms',
    icon: "/images/Renew/Icons/videotomp3.png",
    link:"apps/video-to-mp3-converter-app"
  },
  { 
    id: 5, 
    name: 'Radio FM',
    description: 'Discover new music and radio stations',
    icon: "/images/icons/appIcon/Radio FM.webp",
    link:"apps/radio-monkey-online-radio-fm-app"
  },
  { 
    id: 6, 
    name: 'Photo Collage Maker: Gallery',
    description: 'Enjoy your favorite tracks with enhanced features',
    icon: "/images/Renew/Icons/gallery.png",
    link:"/apps/gallery-photo-editor-and-collage-maker-app"
  },
  { 
    id: 7, 
    name: 'Music Player',
    description: 'Organize and manage your files effortlessly',
    icon: "/images/Renew/Icons/musicplayer.webp",
    link:"/apps/music-player-mp4-mp3-player-app"
  }
];



export const RenewHeaderMegaMenu: React.FC<MegaMenuProp> = ({ setAppMegaMenu }) => {
  return (
    <div 
      className='absolute top-[70px] left-0 bg-[#101010fb] w-screen shadow-2xl z-50' 
      onPointerLeave={() => {setAppMegaMenu(false)}}
    >
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {apps.map((app) => (
            <Link key={app.id}  to={app.link}  className="flex flex-col items-center text-center group cursor-pointer hover:scale-105 transition-transform">
            <div className="w-24 h-24 mb-4 bg-gray-100 rounded-2xl flex items-center justify-center">
              <img 
                src={app.icon} 
                alt={app.name} 
                className="w-16 h-16 object-contain"
              />
            </div>
            <h3 className="font-bold text-gray-200 mb-1">{app.name}</h3>
            <p className="text-xs text-gray-500 px-2">{app.description}</p>
            </Link>
        ))}
      </div>
    </div>
    </div>
  )
}