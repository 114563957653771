import StaggeredFadeIn from "./StaggeredFadeIn";

const TextSection: React.FC = () => {
    return (<div className="text-center text-[#d9d9d9]">
        <StaggeredFadeIn   baseDelay={0} staggerDelay={200} direction="down">
        <p className=" font-inter text-[35px] sm:text-[40px] md:text-[60px] font-bold leading-[60px] pt-6">Everything You Need</p>
        <p className=" font-inter text-[35px] sm:text-[40px] md:text-[60px] font-bold leading-[40px] md:leading-[70px] mb-9">We Create</p>
        <p className="text-[#747474] text-center font-inter text-[18px] md:text-[24px] font-medium ">More than 12+ Best-in-Class <br></br>  Utility Apps for Android and iPhones</p>
        <div className="flex justify-center py-10 gap-4">
        <a href="https://play.google.com/store/apps/dev?id=6259831402052261779&hl=en-IN" target={"_blank"}
            rel="noreferrer nofollow">
            <img src="/images/Renew/assests/googleplay.png"
            alt="Rocks Video Player | Play Store"
            className="w-[120px] h-[40px] md:w-[160px] md:h-[55px]"/>
        </a>
        <a href="https://apps.apple.com/in/developer/ashish-kumar/id1620115071" target={"_blank"}
            rel="noreferrer nofollow">
            <img src="/images/Renew/assests/appstore.png"
            alt="Rocks Video Player | Play Store"
            className="w-[120px] h-[40px] md:w-[160px] md:h-[55px] border-[1.5px] border-slate-500 rounded-md object-cover "/>
        </a>
        </div>
        <div>
        <p className="text-[#747474] font-inter text-[16.797px] font-medium leading-[20px]tracking-[0.196px]">Powered By</p>
        <p className="bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045] font-inter inline-block text-transparent bg-clip-text text-[34.797px] font-bold tracking-[0.196px] ">ASD Dev</p>
        </div>
        </StaggeredFadeIn>
    </div>);
}

export default TextSection;