import React,{useState,useEffect} from "react";

interface StaggeredFadeInProps{
    children:React.ReactNode;
    baseDelay?: number;
    staggerDelay?: number;
    direction?: 'up' | 'down' | 'left' | 'right';
}

const StaggeredFadeIn:React.FC<StaggeredFadeInProps> =({
    children: childrenProp,
    baseDelay = 0,
    staggerDelay = 200,
    direction = 'down'
  })=>{
    const [visibleChildren, setVisibleChildren] = useState<{[key: number]: boolean}>({});
    const children = React.Children.toArray(childrenProp);

    useEffect(() => {
        children.forEach((_, index) => {
          setTimeout(() => {
            setVisibleChildren(prev => ({
              ...prev,
              [index]: true
            }));
          }, baseDelay + (index * staggerDelay));
        });
      }, [baseDelay, staggerDelay, children]);

    const getTransform = (index: number) => {
        const transformMap = {
          'down': visibleChildren[index] ? 'translateY(0)' : 'translateY(50px)',
          'up': visibleChildren[index] ? 'translateY(0)' : 'translateY(-50px)',
          'left': visibleChildren[index] ? 'translateX(0)' : 'translateX(-50px)',
          'right': visibleChildren[index] ? 'translateX(0)' : 'translateX(50px)'
        };
        return transformMap[direction];
      };

return(<>
{children.map((child,index)=>(
    <div key={index} 
    className="transition-all duration-1000 ease-in-out"
    style={{
      opacity: visibleChildren[index] ? 1 : 0,
      transform: getTransform(index)
    }}>
        {child}
    </div>
))
}
</>)
}
export default StaggeredFadeIn;