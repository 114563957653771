import React, { useRef, useState } from "react";
import CountUp from "react-countup";

const OurAchievement = () => {
  const [animate, setAnimate] = useState(false);
  const achiveRef = useRef(null);

  return (
    <div
      className="w-full min-h-[300px] px-4 sm:px-6 md:px-10 py-8 md:py-10 text-black flex justify-center items-center"
      ref={achiveRef}
      onPointerOver={() => setAnimate(true)}
      onPointerLeave={() => setAnimate(true)}
    >
      <div className="glass_brightness w-full max-w-4xl p-4 sm:p-6 md:p-8 rounded-2xl shadow-2xl">
        <h2 className="text-2xl sm:text-3xl md:text-[34px] font-bold text-center py-6 md:py-10 leading-tight">
          Our Achievements
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 md:gap-6">
          <div className="p-4 rounded-lg text-center">
            <CountUp start={20} end={100} redraw={true}>
              {({ countUpRef }) => (
                <div className="text-4xl sm:text-5xl font-bold text-red-600 mb-2">
                  <span ref={countUpRef}></span>M+
                </div>
              )}
            </CountUp>
            <p className="text-black text-sm sm:text-base">Happy Users</p>
          </div>

          <div className="p-4 rounded-lg text-center">
            <CountUp start={20} end={92} redraw={true}>
              {({ countUpRef }) => (
                <div className="text-4xl sm:text-5xl font-bold text-red-600 mb-2">
                  <span ref={countUpRef}></span>%
                </div>
              )}
            </CountUp>
            <p className="text-black text-sm sm:text-base">Positive Reviews</p>
          </div>

          <div className="p-4 rounded-lg text-center">
            <CountUp start={0} end={7} redraw={true}>
              {({ countUpRef, start }) => (
                <div 
                  className="text-4xl sm:text-5xl font-bold text-red-600 mb-2" 
                  onLoad={start}
                >
                  <span ref={countUpRef}></span>+
                </div>
              )}
            </CountUp>
            <p className="text-black text-sm sm:text-base">Years of Experience</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurAchievement;