
const testimonials = [
    {
      name: 'Mia Melo',
      company: 'File Manager',
      image: '/images/Renew/review/1.webp',
      content: 'Excellent appearance, finish, functionality, configuration availability and usability. Very satisfied!'
    },
    {
      name: 'Paul Franco',
      company: 'HD Video Downloader All Formats',
      image: '/images/Renew/review/4.webp',
      content: 'Very good, fast download and everything with good quality.'
    },
    {
      name: 'Paras Soni',
      company: 'HD Video Player All Formats',
      image: '/images/Renew/review/5.webp',
      content: "This app delivers everything it promises. The HD playback is crystal clear, and it handles various formats effortlessly. The user experience is top-notch, with easy navigation and useful features like gesture controls. I've had no crashes or bugs so far, and it's been a joy to use."
    },
    {
      name: 'Suman Arora',
      company: 'Music Player',
      image: '/images/Renew/review/11.webp',
      content: "I really like this music player app, it's beautifully designed, and it allows me to play all kinds of songs and videos. Loved the app so much and going to recommend it to everyone."
    },
    {
      name: 'Severino Ramos',
      company: 'Photo Collage Maker: Gallery',
      image: '/images/Renew/review/2.webp',
      content: "It's great that I started my day with this gallery. Thanks guys for the beautiful work, I liked it! It is worth the Five Stars It does everything you want. It's all beautiful. Good work!"
    },
    {
      name: 'Nahela Naqvi',
      company: 'Video Player: Play Any Video',
      image: '/images/Renew/review/3.webp',
      content: "This video player app offers seamless playback with support for multiple formats. The user interface is clean and intuitive, making it easy to navigate through videos. Highly recommended for all media enthusiasts. Truly impressive."
    },
    {
      name: 'Kenneth Lundine',
      company: 'Video to MP3 Converter',
      image: '/images/Renew/review/6.webp',
      content: "This app worked flawlessly. When I installed the app, what I expected was a simple video-to-mp3 converter. What I did not expect was a full audio editor. This app is simple to use, converts files quickly, and it works great as an audio editor."
    },
    {
      name: 'Shivendra Chauhan',
      company: 'Video Player: Play Any Video',
      image: '/images/Renew/review/7.webp',
      content: 'The app provides excellent video quality and smooth streaming even with large video files. The subtitles feature works flawlessly, enhancing the viewing experience. A must-have for movie lovers.'
    },
    {
      name: 'Harsh Prajapati',
      company: 'HD Video Player All Formats',
      image: '/images/Renew/review/9.webp ',
      content: 'Have all types of formats like MP3, MP4, MOV, etc. It gives a list of features to play videos and songs. This player supports all video formats like HD, Full HD, 4K, and Ultra HD video files. It is one of the best Powerful HD video players for Android.'
    },
    {
      name: 'Vicente Aguilar González',
      company: 'File Manager',
      image: '/images/Renew/review/8.webp',
      content: 'Quick access to your folders and documents, good organization with folders, quick transfer of documents and files.'
    },
    {
      name: 'Dinesh Kumar Pansari',
      company: 'Photo Collage Maker: Gallery',
      image: '/images/Renew/review/10.webp',
      content: 'This app is free to edit photos with numerous filters, effects, stickers, and many powerful editing tools. Creating stunning and professional photos is never difficult with your app.'
    },
    {
      name: 'Suryansh Mishra',
      company: 'HD Video Downloader All Formats',
      image: '/images/homepage/review/Julio.webp',
      content: 'This app has exceeded my expectations. It’s easy to use and reliably downloads videos in high definition. A must-have for anyone who frequently downloads videos.'
    }
  ];

const WhatOurUsersAreSaying:React.FC = () => {

    return(<main className=" w-full ">
       <div className="max-w-7xl mx-auto px-4 py-16">
      <h1 className=" text-3xl md:text-[51.188px] font-bold text-center mb-16 leading-tight:[-0.56px]">What our users are saying.</h1>
      
      <div className="columns-1 md:columns-2 lg:columns-4 gap-6 space-y-6 [column-fill:_balance]">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="break-inside-avoid border border-[#E7E7E7] rounded-[24px] overflow-hidden transition-hover duration-150 hover:shadow-md">
            <div className="w-full bg-white shadow-sm hover:shadow-md transition-shadow">
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-10 h-10 rounded-full mr-3 object-cover"
                  />
                  <div>
                    <h3 className="font-medium text-gray-900">{testimonial.name}</h3>
                    {testimonial.company && (
                      <p className="text-sm text-gray-500">{testimonial.company}</p>
                    )}
                  </div>
                </div>
                <p className="text-gray-600 text-sm">{testimonial.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </main>)
}
export default WhatOurUsersAreSaying;