import React, { useState, useCallback, useEffect } from 'react';
import './LogoStack.css';

interface CardItem {
 id: number;
 imageSrc: string;
}

const cards: CardItem[] = [
 { id: 1, imageSrc: "/images/icons/appIcon/music-player.webp" },
 { id: 2, imageSrc: "/images/Renew/Icons/File manager.png" },
 { id: 3, imageSrc: "/images/Renew/Icons/gallery.png" },
 { id: 4, imageSrc: "/images/icons/appIcon/APS HD Video Downloader.webp" },
 { id: 5, imageSrc: "/images/icons/appIcon/Radio FM.webp" },
 { id: 6, imageSrc: "/images/Renew/Icons/Rocks video player.png" }
];

const LogoStack: React.FC = () => {
 const [currentCards, setCurrentCards] = useState<CardItem[]>(cards);
 const [isAnimating, setIsAnimating] = useState(false);

 const swapCard = useCallback(() => {
   setIsAnimating(true);
   setTimeout(() => {
     setCurrentCards((prevCards) => {
       const [firstCard, ...rest] = prevCards;
       return [...rest, firstCard];
     });
     setIsAnimating(false);
   }, 400);
 }, []);

 useEffect(() => {
   const timer = setInterval(swapCard, 5000);
   return () => clearInterval(timer);
 }, [swapCard]);

 return (
 <div className='pt-20'>
 <div className="stack ">
     {currentCards.map((card, index) => (
       <div
         key={card.id}
         className={`overflow-hidden card card--${index >= currentCards.length - 3 ? 'back' : index === currentCards.length - 2 ? 'middle' : index === currentCards.length - 1 ? 'front' : 'top'}`}
         onClick={swapCard}
       >
         {index === currentCards.length - 1 && (
           <img 
             src={card.imageSrc} 
             alt={`Card ${card.id}`} 
             className="bg-white"
           />
         )}
       </div>
     ))}
   </div>
  </div>
 );
};

export default LogoStack;