
import RenewHeader from "../../comp/Renew/RenewHeader";
import "./NewHome.css";
import { Helmet } from "react-helmet";
import LogoStack from "../../comp/Renew/LogoStack";
import TextSection from "../../comp/Renew/TextSection";
import FlowingIcons from "../../comp/Renew/FlowingIcons";
import AppsDetails from "../../comp/Renew/AppsDetails";
import StaggeredFadeIn from "../../comp/Renew/StaggeredFadeIn";
import WhatOurUsersAreSaying from "../../comp/Renew/WhatOurUsersAreSaying";
import "../../comp/Renew/Renew.css";
import Footer from "../../comp/Renew/Footer";
import GlassFAQ from "../../comp/Renew/GlassFAQ";
import LogoMarquee from "../../comp/Renew/LogoMarquee";
import ImageGallery from "../../comp/Renew/ImageGallery";
import GamesSection from "../../comp/Renew/GamesSection";


const NewHome = () => {
  const fetchJson = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "Rareprob Solutions Private Limited",
      image: "https://rareprob.com/images/newDesign/PHONES.webp",
      "@id": "https://rareprob.com/images/newDesign/PHONES.webp",
      url: "https://rareprob.com/",
      telephone: "82793 23413",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "Suite 1305, second floor, Building 1000, Doon business express park, opposite Transport Nagar, Subhash Nagar",
        addressLocality: "Dehradun",
        postalCode: "248001",
        addressCountry: "IN",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 30.2817743,
        longitude: 77.9885862,
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "09:00",
        closes: "20:00",
      },
      sameAs: [
        "https://www.facebook.com/rareprob",
        "https://twitter.com/rareprob",
        "https://www.instagram.com/rareprob_/",
        "https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ",
        "https://www.linkedin.com/company/rareprob/",
        "https://in.pinterest.com/rareprob/",
        "https://rareprob.com/",
      ],
    });

  const fetchJson2 = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What are the Utility apps?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The utility apps allow users to meet their basic digital tasks with the most advanced features and exceptional user-friendly interface, which is far better than your device’s default apps. These Utility apps are regularly updated to perform multiple functionalities effortlessly.",
          },
        },
        {
          "@type": "Question",
          name: "What is ASD Dev?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "ASD Dev stands for ASD Dev Video Player for All Format is the developer's account of Rareprob Solution on the Play Store. You can visit the Rareprob website and ASD Dev's app on Playstore where you can explore all the apps.",
          },
        },
        {
          "@type": "Question",
          name: "Why use the ASD Dev app?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The ASD Dev is made of some of the finest software engineers in our country who develops mobile app to provide you with multiple features and functionalities such as you can download your favorite videos and images from any social media platform, video hider, managing downloads, multiple language support, watching and listening to high-quality media content, managing your digital documents and much more.",
          },
        },
        {
          "@type": "Question",
          name: "What does ASD Dev have to offer?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "ASD Dev app offers you multiple daily utility Android applications such as ASD Rocks Video Player, ASD File Manager, ASD Video Downloader, ASD Gallery app, ASD Music Player, and many more.",
          },
        },
      ],
    });



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explore Our Utility Mobile Apps for Every Need | Rareprob</title>
        <link rel="canonical" href="https://rareprob.com" />
        <meta
          name="description"
          content="Discover a range of utility mobile apps designed to enhance productivity, entertainment, and convenience on various platforms."
        />
        <meta
          property="og:title"
          content="Explore Our Utility Mobile Apps for Every Need | Rareprob"
        />
        <meta property="og:site_name" content="RareProb Solutions" />
        <meta property="og:url" content="https://rareprob.com/" />
        <meta
          property="og:description"
          content="Discover a range of utility mobile apps designed to enhance productivity, entertainment, and convenience on various platforms."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://rareprob.com/images/homepage/web%20page.webp"
        ></meta>
        <script type="application/ld+json">{fetchJson()}</script>
        <script type="application/ld+json">{fetchJson2()}</script>
      </Helmet>

     
   
    
      <main className="w-full min-h-screen bg-[#000] relative overflow-hidden" 
 
       >
           <RenewHeader />
          <div className="cursor"></div>
        <StaggeredFadeIn   baseDelay={100} staggerDelay={300} direction="down">
        <LogoStack/>
        <TextSection/>
        </StaggeredFadeIn>
</main>
<main   id="apps-details-section">
<AppsDetails/>
<GamesSection/>
 <FlowingIcons /> 
 <ImageGallery/>
 <WhatOurUsersAreSaying/>
 <LogoMarquee/>
 <GlassFAQ/>

 <Footer/>
</main>
    </>
  );
};

export default NewHome;
