import { Link } from "react-router-dom";

const GamesSection = () => {
  const games = [
    {
      name: "War Tank",
      image: "https://asset.amezgame.com/websitegameiconwebp/wartank.webp"
    },
    {
      name: "Word Jungle",
      image: "https://play.amezgame.com/wordjungle/logo.png"
    },
    {
      name: "Dummy Dash",
      image: "https://asset.amezgame.com/websitegameiconwebp/dummydash.webp"
    },
    {
      name: "Pizza Beats",
      image: "https://play.amezgame.com/pizzabeats/logo.png"
    },
    {
      name: "Christmas Battle",
      image: "https://play.amezgame.com/christmasbattle/logo.png"
    }
  ];

  return (
    <main className="relative w-full min-h-[350px] bg-black px-4 py-10">
      <div 
        className="absolute inset-0 opacity-20"
        style={{
          backgroundImage: `url('/images/Renew/footerTopImage.png')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: "right"
        }}
      />
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 lg:px-8">
        <div className="mb-8">
          <p className="text-white text-2xl md:text-[28px] font-bold">
            Discover our Mobile & Web games
          </p>
          <p className="text-white text-2xl md:text-[28px] font-bold">
            get Amazed by Amez Games
          </p>
          <div className="mt-6">
            <Link to="https://amezgame.com/">
            <button className="w-[130px] px-3 py-2 bg-white rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Visit Us
            </button>
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-8">
          {games.map((game, index) => (
            <div 
              key={index} 
              className="relative group cursor-pointer"
            >
              <div className="aspect-square w-full overflow-hidden rounded-lg bg-gray-800">
                <img
                  src={game.image}
                  alt={game.name}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                />
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center rounded-lg">
                <span className="text-white font-medium text-sm">
                  {game.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default GamesSection;





