import { useState, useRef, useEffect } from "react";
import { RenewHeaderMegaMenu } from "./RenewHeader_MegaMenu";
import { Link } from "react-router-dom";

interface HeaderProps {
  color?: string;
  page?: string;
}

const RenewHeader: React.FC<HeaderProps> = ({
  color = "bg-black/25",
  page = "home",
}) => {
  const [appMegaMenu, setAppMegaMenu] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toolbarRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the mobile menu to close it
  const handleClickOutside = (e: MouseEvent | TouchEvent) => {
    if (toolbarRef.current && !toolbarRef.current.contains(e.target as Node)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <div
      ref={toolbarRef}
      className="absolute top-0 w-[100%] h-[70px] flex justify-center items-center z-50 px-5 md:px-7 lg:px-32 py-5 backdrop-blur-[12px]"
      style={{ backgroundColor: color }}
    >
      <div className="w-[100%] h-[100%] flex md:gap-5 lg:gap-[24px] items-center justify-between text-[#dcdcdc]">
        <div className="flex gap-2 items-center text-2xl font-bold">
          <img
            src="/logo192.png"
            className="w-auto h-[30px]"
            alt="Rareprob Logo"
          />
          Rareprob
        </div>
        <div className="hidden lg:flex gap-6">
          <Link to="/"><p className="font-medium">Home</p></Link>
          <p
            className="font-medium"
            onPointerEnter={() => setAppMegaMenu(true)}
          >
            Apps
          </p>
          <Link to="/games"><p className="font-medium">Games</p></Link>
          <Link to="/about-us"><p className="font-medium">About</p></Link>
          <Link to="/faq"><p className="font-medium">FAQ</p></Link>
          <Link to="/career"><p className="font-medium">Career</p></Link>
          <Link to="/contact-us"><p className="font-medium">Contact</p></Link>
        </div>
        <div
          className="w-[30px] h-[30px] flex justify-center items-center lg:hidden cursor-pointer mx-5"
          onClick={toggleMobileMenu}
          aria-label="Toggle mobile menu"
          role="button"
        >
          <svg
            className="fill-current h-5 w-5"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </div>
      </div>
      {appMegaMenu && <RenewHeaderMegaMenu setAppMegaMenu={setAppMegaMenu} />}
      {isMobileMenuOpen && (
        <div className="lg:hidden absolute top-[70px] left-0 w-full h-auto z-30 ">
          <div className="flex flex-col text-white bg-[#0b0b0df9] backdrop:blur-3xl backdrop-brightness-95">
            <a href="/">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">Home</span>
              </div>
            </a>
            <a href="/apps">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">Apps</span>
              </div>
            </a>
            <a href="/games">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">Games</span>
              </div>
            </a>
            <a href="/about">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">About</span>
              </div>
            </a>
            <a href="/blogs">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">Blogs</span>
              </div>
            </a>
            <a href="/faq">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">FAQ</span>
              </div>
            </a>
            <a href="/career">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">Career</span>
              </div>
            </a>
            <a href="/contact-us">
              <div className="border-b-[1px] border-white/20 py-3 px-4">
                <span className="text-xl">Contact</span>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default RenewHeader;