import React, { useState, useEffect, useRef } from 'react';
import ScrollText from './ScrollText';
import OurAchievment from './OurAchivement';
const colors = [
    {   
        id: 1,
        url: '/images/icons/appIcon/music-player.webp', 
        top: '5%',
        left: '54.9%',
        animation:'floating-drift'
    },
    {   
        id: 2,
        url: '/images/Renew/Icons/File manager.png', 
        top: '11.4%',
        left: '89.1%',
        animation:'floating-sway'
    },
    {   
        id: 3,
        url: "/images/Renew/Icons/gallery.png", 
        top: '88.6%',
        left: '74.5%',
        animation:'floating-fluid'
    },
    {   
        id: 4,
        url: '/images/icons/appIcon/APS HD Video Downloader.webp', 
        top: '14.3%',
        left: '31.4%',
        animation:'floating-smooth'
    },
    {   
        id: 5,
        url: '/images/Renew/Icons/Radio FM.webp', 
        top: '3.1%',
        left: '10.9%',
        animation:'floating-wave'
    },
    {   
        id: 6,
        url: 'https://www.appspacesolutions.in/images/icon/calmverse.jpg', 
        top: '20%',
        left: '65.6%',
        animation:'floating-subtle'
    },
    {   
        id: 7,
        url: 'https://www.appspacesolutions.in/images/icon/vpn.jpg', 
        top: '42.3%',
        left: '15.6%',
        animation:'floating-glide'
    },
    {   
        id: 8,
        url: 'https://www.appspacesolutions.in/images/icon/zx.jpg', 
        top: '45.7%',
        left: '77.1%',
        animation:'floating-fluid-drift'
    },
    {   
        id: 9,
        url: '/images/icons/appIcon/music-player.webp', 
        top: '66.6%',
        left: '5%',
        animation:'floating-gentle-sway'
    },
    {   
        id: 10,
        url: '/images/Renew/Icons/Rocks video player.png', 
        top: '85.7%',
        left: '22.6%',
        animation:'floating-soft-flow'
    },
    {   
        id: 11,
        url: '/images/Renew/Icons/Video player.png', 
        top: '90.3%',
        left: '48%',
        animation:'floating-soft-flow'
    }
];

const FlowingIcons: React.FC = () => {
  const [isScattered, setIsScattered] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isScattered) {
          setIsScattered(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    
    return () => observer.disconnect();
  }, [isScattered]);

  return (
    <main 
      className="w-full h-[80vh] flex justify-center items-center relative overflow-hidden"
    >
      {colors.map((color, index) => (
        <div 
          key={index} 
          ref={containerRef} 
          className={`h-[60px] w-[60px] rounded-lg overflow-hidden absolute transition-all duration-[2000ms] ease-out ${color.animation}`}
          style={{
            top: isScattered ? color.top : '50%',
            left: isScattered ? color.left : '50%',
          }}><img src={color.url} className='object-cover' alt={color.url}/>
        </div>
      ))}
      <OurAchievment/>
    </main>
  );
};

export default FlowingIcons;


