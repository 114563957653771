import React from 'react';
import OurAchievment from './OurAchivement';

const LogoMarquee: React.FC = () => {
  const Row1 = [
    { name: 'Radio Monkey', logo: '/images/Renew/Icons/Radio FM.webp' },
    { name: 'Gallery', logo: '/images/Renew/Icons/gallery.png' },
    { name: 'File Manager', logo: '/images/Renew/Icons/File manager.png' },
    { name: 'Music Player', logo: '/images/icons/appIcon/music-player.webp' },
    { name: 'Video Downloader', logo: '/images/Renew/Icons/videoDownloader.webp' },
    { name: 'Rocks Player', logo: '/images/Renew/Icons/Video player.png' }
  ];
  const Row2 = [
    { name: 'ZX File Manager', logo: 'https://www.appspacesolutions.in/images/icon/zx.jpg' },
    { name: 'Pronto VPN', logo: 'https://www.appspacesolutions.in/images/icon/vpn.jpg' },
    { name: 'Clam Sleep', logo: 'https://www.appspacesolutions.in/images/icon/calmverse.jpg' },
    { name: 'HDX File Manager', logo: 'https://www.appspacesolutions.in/images/icon/hdx.jpg' },
    { name: 'Video Editor Master', logo: 'https://www.appspacesolutions.in/images/icon/videomaster.jpg' },
    { name: 'Collage Maker', logo: 'https://www.appspacesolutions.in/images/icon/collagemaker.png' }
  ];
  const Row3 = [
    { name: 'War Tank', logo: 'https://asset.amezgame.com/websitegameiconwebp/wartank.webp' },
    { name: 'Juicy Fruit Blast', logo: 'https://play.amezgame.com/juicyfruitblast/logo.png' },
    { name: 'Dummy Dash', logo: 'https://asset.amezgame.com/websitegameiconwebp/dummydash.webp' },
    { name: 'Turbo Racing', logo: 'https://asset.amezgame.com/websitegameiconwebp/turbocraracing.webp' },
    { name: 'Pizza Beats', logo: 'https://play.amezgame.com/pizzabeats/logo.png' },
    { name: 'Christmas Battle', logo: 'https://play.amezgame.com/christmasbattle/logo.png' }
  ];


  return (
    <div className="w-full bg-white py-10 overflow-hidden">
      {/* First Row - Right to Left */}
      <div className="relative flex overflow-x-hidden group">
        <div className="animate-marquee-rtl flex items-center whitespace-nowrap">
          {[...Row1, ...Row1].map((item, idx) => (
            <div key={idx} className="flex items-center mx-10 md:mx-12">
              <img src={item.logo} alt={item.name} className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] rounded-xl" />
              <span className="ml-3 md:ml-2 text-2xl md:text-3xl font-bold">{item.name}</span>
            </div>
          ))} 
        </div>
        <div className="absolute top-0 animate-marquee2-rtl flex items-center whitespace-nowrap">
          {[...Row1, ...Row1].map((item, idx) => (
            <div key={idx} className="flex items-center mx-10 md:mx-12">
              <img src={item.logo} alt={item.name} className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] rounded-xl" />
              <span className="ml-3 md:ml-2 text-2xl md:text-3xl font-bold">{item.name}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Second Row - Left to Right */}
      <div className="relative flex overflow-x-hidden mt-6 group ">
        <div className="animate-marquee-ltr flex items-center whitespace-nowrap">
          {[...Row2, ...Row2].map((item, idx) => (
            <div key={idx} className="flex items-center mx-10 md:mx-12">
              <img src={item.logo} alt={item.name} className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] rounded-xl" />
              <span className="ml-3 md:ml-2 text-2xl md:text-3xl font-bold">{item.name}</span>
            </div>
          ))}
        </div>
        <div className="absolute top-0 animate-marquee2-ltr flex items-center whitespace-nowrap">
          {[...Row2, ...Row2].map((item, idx) => (
            <div key={idx} className="flex items-center mx-10 md:mx-12">
              <img src={item.logo} alt={item.name} className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] rounded-xl" />
              <span className="ml-3 md:ml-2 text-2xl md:text-3xl font-bold">{item.name}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Third Row - Right to Left */}
      <div className="relative flex overflow-x-hidden mt-6 group">
        <div className="animate-marquee-rtl flex items-center whitespace-nowrap">
          {[...Row3, ...Row3].map((item, idx) => (
            <div key={idx} className="flex items-center mx-10 md:mx-12">
              <img src={item.logo} alt={item.name} className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] rounded-xl" />
              <span className="ml-3 md:ml-2 text-2xl md:text-3xl font-bold">{item.name}</span>
            </div>
          ))}
        </div>
        <div className="absolute top-0 animate-marquee2-rtl flex items-center whitespace-nowrap">
          {[...Row3, ...Row3].map((item, idx) => (
            <div>
              <div key={idx} className="flex items-center mx-10 md:mx-12">
              <img src={item.logo} alt={item.name} className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] rounded-xl" />
              <span className="ml-3 md:ml-2 text-2xl md:text-3xl font-bold">{item.name}</span>
            </div> 
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoMarquee;