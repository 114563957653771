import { useState,useRef,useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

const data = [
  {
    id: 0,
    name: "RocksPlayer",
    icon: "/images/Renew/Icons/Video player.png",
    phone: "",
    maincolor: "#FFE8E7",
    maininsidecolor: "#FFF2F2",
    sidecolor: "#D21F1A",
    bordercolor:"red-900",
    sideimg: "/images/Renew/ScreenMockups/Rocksplayermockup.svg",
    features: [
      {
        icon: "🎬",
        title: "HD Video Player All Formats",
        description: "Rocks Player is a 4K video player that lets you watch HD videos in multiple formats like MP3, MP4, MOV, 3GP, MKV, TS, FLV, RMVB, etc."
      },
      {
        icon: "💬",
        title: "Subtitle Support",
        description: "Say goodbye to the language barrier! Rocks video player offers free offline and online subtitles. Enjoy movies like never before."
      },
      {
        icon: "⏯️",
        title: "Playback Options",
        description: "Rocks Player offers different playback options like Background playback and the PiP mode. Improve your productivity with these playback options.",
        badge: "Update"
      },
      {
        icon: "⬇️",
        title: "Video Downloader",
        description: "Enjoy fast and easy downloads from social media platforms like Instagram, Facebook, Vimeo, X (previously Twitter), and more."
      },
      {
        icon: "🔐",
        title: "Video Hider",
        description: "PIN-protect your private media files like photos, videos, and music with our advanced encrypted Hider functionality.",
        badge: "Update"
      },
      {
        icon: "📺",
        title: "Cast to TV",
        description: "Cast your favorite videos on TV with a single click. Enhance your video-watching experience with the best 4K video player."
      }
    ]
  },
  {
    id: 1,
    name: "File Manager",
    icon: "/images/Renew/Icons/File manager.png",
    phone: "",
    maincolor: "#FCF4DF",
    maininsidecolor: "#FFFAEE",
    sidecolor: "#FDE272",
    sideimg: "/images/Renew/ScreenMockups/Filemanagermockup.svg",
    features: [
      {
        icon: "📱",
        title: "All-in-one App",
        description: "A comprehensive file manager that lets you securely organize, access, and share your documents, media, and other files with ease. Enjoy seamless navigation and an intuitive interface for effortless file management."
      },
      {
        icon: "🔐",
        title: "Callock",
        description: "Keep your private files protected with Callock. Secure your media, documents, and other important data with a PIN lock, ensuring that only you have access to your sensitive information."
      },
      {
        icon: "🧹",
        title: "Clean Master",
        description: "Effortlessly clean, organize, and optimize your device storage with a single tap. Remove junk files, duplicate media, and unnecessary data to free up space and enhance performance.",
        badge: "Update"
      },
      {
        icon: "💾",
        title: "Status Saver",
        description: "Never lose a memorable WhatsApp status again! Easily save and store your favorite WhatsApp statuses, including images and videos, so you can revisit and share them anytime."
      },
      {
        icon: "⬇️",
        title: "Video Downloader",
        description: "Download videos from social media platforms and websites with ease. Save your favorite clips, movies, and media content directly to your device for offline viewing anytime.",
        badge: "Update"
      },
      {
        icon: "📤",
        title: "ShareOn",
        description: "Experience ultra-fast file sharing with ShareOn. Instantly transfer photos, videos, music, documents, and even apps to friends and family without the need for an internet connection."
      }
    ]
  },
  {
    id: 2,
    name: "Video Downloader",
    icon: "/images/Renew/Icons/APSHDVideoDownloader.png",
    phone: "",
    maincolor: "#F0FFDA",
    maininsidecolor: "#FAFFF2",
    sidecolor: "#79AF24",
    sideimg: "/images/Renew/ScreenMockups/Videodownloadermockup.svg",
    features: [
      {
        icon: "📱",
        title: "Social Media Downloader",
        description: "Download your favorite social media videos in HD instantly. Save, share, and enjoy high-quality videos with our fast, easy-to-use downloader. No hassle, just seamless downloads!"
      },
      {
        icon: "⬇️",
        title: "Easy Download",
        description: "Download your favorite videos in HD quality! Enjoy high-quality downloads from your favorite sites, effortlessly and quickly with our HD Video Downloader app."
      },
      {
        icon: "🌐",
        title: "Fast & Safe Browsing",
        description: "With our fast and secure browser, enjoy internet surfing and downloading HD videos quickly and safely. Enjoy a smooth experience with safe, instant downloads from your favorite websites.",
        badge: "Update"
      },
      {
        icon: "▶️",
        title: "Built-in HD Video Player",
        description: "Instantly download HD videos and play them directly with our integrated HD Video Player. A smooth, high-quality experience from download to watching!"
      },
      {
        icon: "📁",
        title: "Easy File Access",
        description: "Enjoy seamless access to your media library with the powerful HD Video Downloader. File access is simple, quick, and ready at your fingertips!",
        badge: "Update"
      },
      {
        icon: "📊",
        title: "Download Progress",
        description: "Stay updated with the real-time progress of your downloads. Track the real-time progress as you download your favorite content in HD with ease and speed."
      }
    ]
  },
  {
    id: 3,
    name: 'Video To Mp3 Converter',
    icon: "/images/Renew/Icons/videotomp3.png",
    phone: "",
    maincolor: "#FFE4F5",
    maininsidecolor: "#FFF3FB",
    sidecolor: "#D4188B",
    sideimg: "/images/Renew/ScreenMockups/mp3mockup.svg",
    features: [
      {
        icon: "🎵",
        title: "Video to MP3",
        description: "Extract audio from videos and save as MP3 in just a few clicks. Our converter makes it quick and easy to extract high-quality audio for free."
      },
      {
        icon: "✂️",
        title: "Audio & Video Trimmer",
        description: "Trim your audio and video clips with precision. With a user-friendly interface and fast results, it's the perfect converter for you."
      },
      {
        icon: "📱",
        title: "Set Ringtone",
        description: "Personalize your device by setting custom ringtones, notifications, and alarms. Make every sound uniquely yours!",
        badge: "Update"
      },
      {
        icon: "🔄",
        title: "Merge Audio",
        description: "Convert videos to MP3 and seamlessly merge multiple audio tracks into a single, smooth file. Perfect for creating custom playlists and audio mixes on the go!"
      },
      {
        icon: "🔄",
        title: "Format Converter",
        description: "Quickly transform videos into high-quality MP3 files or any other with a simple tap. Enjoy your favorite audio anywhere, anytime.",
        badge: "Update"
      },
      {
        icon: "🎙️",
        title: "Recorder",
        description: "Record your screen with crystal-clear audio in just a tap! Enjoy high-quality MP3 conversion and smooth screen recording with sound."
      }
    ]
  },
  {
    id: 4,
    name: "Photo Collage Maker: Gallery",
    icon: "/images/Renew/Icons/gallery.png",
    phone: "",
    maincolor: "#bbdefb",
    maininsidecolor: "#e3f2fd",
    sidecolor: "#42a5f5",
    sideimg: "/images/Renew/ScreenMockups/Gallerymockup.svg",
    features: [
      {
        icon: "🎨",
        title: "Collage Maker",
        description: "Create stunning photo collages effortlessly! Combine your favorite photos, add stylish effects, and create eye-catching collages with our feature-packed Photo Collage Maker & Gallery."
      },
      {
        icon: "✏️",
        title: "Photo Editor",
        description: "Gallery meets pro editing! Crop, adjust, filter, and fine-tune your photos effortlessly. Make every shot Instagram-ready with just a few taps."
      },
      {
        icon: "📸",
        title: "Gallery",
        description: "Organize, browse and share your memories effortlessly with our smart Gallery app. Secure your memories, create albums, and relive your favorite moments with a smart, intuitive Gallery app.",
        badge: "Update"
      },
      {
        icon: "🔐",
        title: "Gallery Vault",
        description: "Lock away your sensitive media in a PIN-protected, encrypted Vault for complete privacy and security. Lock, hide, and protect your private data from prying eyes."
      },
      {
        icon: "💾",
        title: "Status Downloader",
        description: "One-tap Status Downloader! Instantly download and organize your favorite WhatsApp statuses right from your gallery. Never miss a moment again!",
        badge: "Update"
      },
      {
        icon: "⬇️",
        title: "In-built Downloader",
        description: "Download and organize your favorite images seamlessly! Enjoy easy access to all your downloaded photos in one place with our built-in downloader."
      }
    ]
  },
  {
    id: 5,
    name: "Music Player",
    icon: "/images/Renew/Icons/musicplayer.webp",
    phone: "",
    maincolor: "#FFE8E7",
    maininsidecolor: "#FFF2F2",
    sidecolor: "#E22464",
    sideimg: "/images/Renew/ScreenMockups/MusicPlayermockup.svg",
    features: [
      {
        icon: "🎚️",
        title: "Equalizer",
        description: "Fine-tune every beat, from deep bass to high treble, and enjoy the perfect sound tailored to you. Enhance your music experience with our built-in Equalizer."
      },
      {
        icon: "😴",
        title: "Calm Sleep",
        description: "Relax and unwind with our calm sleep feature. Our calm music presets gently lull you into a deep, restful slumber. Perfect for your nightly routine."
      },
      {
        icon: "🔄",
        title: "Video to MP3 Converter",
        description: "Easily turn videos into MP3 files with our built-in converter. Get high-quality audio from your favorite videos and expand your music collection.",
        badge: "Update"
      },
      {
        icon: "⏰",
        title: "Sleep Timer",
        description: "Set the sleep timer and let your music play until you drift off to sleep. Control when your music stops with the sleep timer feature."
      },
      {
        icon: "📝",
        title: "Playlists",
        description: "Dive into a world of sound with our music player app. Create your own playlists with ease, keep track of your songs, and listen to your favorite music on the go.",
        badge: "Update"
      },
      {
        icon: "🎨",
        title: "Themes",
        description: "Elevate your music experience with our app that lets you customize every detail. Select from a collection of stunning themes or choose from your collection and make your player unique."
      }
    ]
  }
];

const AppsDetails: React.FC = () => {
  const [activeApp, setActiveApp] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const autoSlideInterval = useRef<NodeJS.Timeout>();

  // Auto slide functionality
  useEffect(() => {
    startAutoSlide();
    return () => {
      if (autoSlideInterval.current) {
        clearInterval(autoSlideInterval.current);
      }
    };
  }, []);

  // New effect to handle scrolling to active icon
  useEffect(() => {
    scrollToActiveIcon();
  }, [activeApp]);

  const scrollToActiveIcon = () => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const activeElement = container.children[0].children[activeApp] as HTMLElement;
      
      if (activeElement) {
        const containerWidth = container.offsetWidth;
        const elementOffset = activeElement.offsetLeft;
        const elementWidth = activeElement.offsetWidth;
        
        // Calculate the center position
        const scrollPosition = elementOffset - (containerWidth / 2) + (elementWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  const startAutoSlide = () => {
    if (autoSlideInterval.current) {
      clearInterval(autoSlideInterval.current);
    }
    autoSlideInterval.current = setInterval(() => {
      setActiveApp((prev) => (prev + 1) % data.length);
    }, 6900);
  };

  const handleAppClick = (id: number) => {
    setActiveApp(id);
    startAutoSlide();
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 200;
      const newScrollPosition = direction === 'left' 
        ? scrollRef.current.scrollLeft - scrollAmount
        : scrollRef.current.scrollLeft + scrollAmount;
      
      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  // Rest of your component remains the same, just update the onClick handler
  return(
    <main className="w-full py-8 md:py-16 px-4 md:px-8">
    <div className="flex justify-between">
      <span className="text-[#222] font-roboto text-xl md:text-2xl font-bold leading-normal md:leading-[44.16px] block mb-6">
        APPS WE'VE CREATED
      </span>
      <div className="relative h-9 w-20 md:hidden right-0">
        <button 
          onClick={() => handleScroll('left')}
          className="absolute left-0 top-1/3 -translate-y-1/2 z-10 md:hidden bg-white/80 p-2 rounded-full shadow-md"
        >
          <FaArrowLeft size={15}/>
        </button>
        <button 
          onClick={() => handleScroll('right')}
          className="absolute right-0 top-1/3 -translate-y-1/2 z-10 md:hidden bg-white/80 p-2 rounded-full shadow-md"
        >
          <FaArrowRight size={15}/>
        </button>
      </div>
    </div>

    <div 
      ref={scrollRef}
      className="overflow-x-auto pb-4 scrollbar-hide scroll-smooth"
    >
      <div className="h-auto md:h-[130px] min-w-max flex gap-4 md:gap-10 items-center justify-start md:justify-around px-2">
        {data.map((md) => (
          <div
            key={md.id}
            className={`flex-none md:flex-1 min-w-[100px] md:min-w-0 cursor-pointer transition-all duration-300`}
            style={activeApp === md.id ? { borderBottom: `2px solid ${md.sidecolor}` } : {}}
            onClick={() => handleAppClick(md.id)}
          >
            <div className="p-2 flex justify-center items-center">
              <img
                src={md.icon}
                alt="Icons"
                className="w-[40px] h-[40px] md:w-[60px] md:h-[60px] object-cover"
              />
            </div>
            <p className="text-center text-sm md:text-base pb-2">{md.name}</p>
          </div>
        ))}
      </div>
    </div>

      {/* Main Content Section - remains the same */}
      <div className="flex flex-col lg:flex-row gap-6 md:gap-10 w-full h-auto mt-[20px]">
        {/* Phone Preview Section */}
        <div
          className="h-[300px] lg:h-[500px] w-full lg:w-[25%] rounded-3xl flex justify-center items-center"
          style={{
            backgroundColor: `${data[activeApp].sidecolor}`,
            backgroundImage: `url('/images/Renew/main_featured.png')`,
            backgroundPosition: 'bottom',
            backgroundSize: 'contain',
            backgroundRepeat: "no-repeat"
          }}
        >
          <img 
            src={data[activeApp].sideimg} 
            alt="Phone" 
            className="object-cover h-[80%] w-auto"
          />
        </div>
        
        {/* Features Section */}
        <div
          className="flex flex-col gap-4 h-auto w-full lg:w-[75%] px-4 md:px-[30px] py-[25px] rounded-3xl"
          style={{ backgroundColor: data[activeApp].maincolor }}
        >
          <h1 className="text-xl md:text-2xl font-bold">{data[activeApp].name}</h1>
          <div
            className="w-auto flex-1 rounded-3xl"
            style={{
              backgroundColor: data[activeApp].maininsidecolor,
            }}
          >
            <div 
              className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 p-4 md:p-6 rounded-2xl"
              style={{ backgroundColor: data[activeApp].maininsidecolor }}
            >
              {data[activeApp].features.map((feature, index) => (
                <div key={index} className="flex gap-3 md:gap-4">
                  <span className="text-xl md:text-2xl">{feature.icon}</span>
                  <div className="flex-1">
                    <div className="flex items-center gap-2 flex-wrap">
                      <h3 className="font-semibold text-sm md:text-base">{feature.title}</h3>
                      {feature.badge && (
                        <span className="px-2 py-0.5 text-xs bg-green-500 text-white rounded">
                          {feature.badge}
                        </span>
                      )}
                    </div>
                    <p className="text-xs md:text-sm text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AppsDetails;


